import { useLocation } from 'js/lib/useRouter';

import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';

export const useIsCourseraPlusPromoPage = () => {
  const { pathname } = useLocation();

  return pathname.includes('courseraplus/special');
};

export const useImpressionForCourseraPlusPromoRedesign = () => {
  const isPromoPage = useIsCourseraPlusPromoPage();
  const generateImpressionForCourseraPlusPromoRedesign = (isLearnerEligible: boolean) => {
    if (isLearnerEligible && isPromoPage) {
      courseraPlusExperiments.get('isCourseraPlusPromoRedesignEnabled');
    }
  };

  return { generateImpressionForCourseraPlusPromoRedesign };
};

export const useIsRedesignPromoPageEnabled = () => {
  const { pathname } = useLocation();
  const isPromoPage = pathname.includes('courseraplus/special');

  return isPromoPage && courseraPlusExperiments.preview('isCourseraPlusPromoRedesignEnabled');
};
