/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ThemeProvider } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import connectToStores from 'js/lib/connectToStores';
import { useLocation } from 'js/lib/useRouter';

import CourseraPlusHubPageMetatags from 'bundles/coursera-plus-landing-page/components/CourseraPlusHubPageMetatags';
import { useIsRedesignPromoPageEnabled } from 'bundles/coursera-plus-landing-page/utils/useIsRedesignPromoPageEnabled';
import { BUNDLE_MAP } from 'bundles/coursera-plus/utils/bundleUtils';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import type { InputProps as PageHeaderProps } from 'bundles/page-header/components/PageHeader';
import PageHeader from 'bundles/page-header/components/PageHeader';
import { searchTheme } from 'bundles/search-common/searchTheme';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

import 'css!./styl/index';

const PAGE_HEADER_CLASSNAME_OVERRIDE = 'page-header-classname-override';

const styles = {
  container: css`
    @media (max-width: 607px) {
      padding-bottom: 52px;
    }

    .${PAGE_HEADER_CLASSNAME_OVERRIDE} {
      background-color: #f0f6ff;
    }
  `,
};

type PropsFromStores = {
  csrfToken: string;
  requestCountryCode: string;
};

const CourseraPlusHubApp = (props: React.PropsWithChildren<PropsFromStores>) => {
  const { children } = props;
  const { pathname } = useLocation();
  const isMarketing = pathname.includes('subscribe') || pathname.includes('special');
  const shouldDisableIndexing = isMarketing;
  const shouldDisablePageHeaderControls = isMarketing;
  const isCollectionLandingPage = Object.keys(BUNDLE_MAP).some((bundleId) => pathname.includes(bundleId));
  const isRedesignPromoPageEnabled = useIsRedesignPromoPageEnabled();

  const defaultHeaderProps: PageHeaderProps = {
    isPageWithSecondaryCta: true,
    hideSearch: shouldDisablePageHeaderControls,
    hideMembershipSwitcher: shouldDisablePageHeaderControls,
    hideEnterprise: shouldDisablePageHeaderControls,
    hideRightNav: shouldDisablePageHeaderControls,
    disableHeaderLogoUserInteraction: shouldDisablePageHeaderControls,
    showShoppingCart: !shouldDisablePageHeaderControls,
    isScrollable: false,
    hideMetaNav: isCollectionLandingPage || isMarketing,
    pageHeaderClassnameOverride: isRedesignPromoPageEnabled ? PAGE_HEADER_CLASSNAME_OVERRIDE : '',
  };

  return (
    <ThemeProvider theme={searchTheme}>
      <div className="rc-CourseraPlusHubApp" css={styles.container}>
        <CourseraPlusHubPageMetatags disableCrawlerIndexing={shouldDisableIndexing} />
        <PageHeader {...defaultHeaderProps} />
        <div role="main">{children}</div>
        <PageFooter />
      </div>
    </ThemeProvider>
  );
};

export default compose<PropsFromStores, {}>(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'premium_hub',
      page: 'coursera_plus_landing_page',
    },
  })),
  connectToStores<PropsFromStores, {}>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  }))
)(CourseraPlusHubApp);
